import React from "react";

import "./Loading.css";

import LoadingImg from "assets/images/pizza-loader-min-192.png";

interface LoadingProps extends Readonly<{}> {}
interface LoadingState {}

export default class Loading extends React.Component<LoadingProps, LoadingState> {
	private dots: number;
	private insterval: NodeJS.Timeout | null;

	constructor(props: LoadingState) {
		super(props);

		this.dots = 0;
		this.insterval = null;
	}

	componentDidMount() {
		const title = document.querySelector("#loader h1");

		if (this.insterval == null && title != null) {
			this.insterval = setInterval(() => {
				if (this.dots < 3) {
					title.textContent += ".";
					this.dots++;
				} else {
					title.textContent = title.textContent?.replaceAll(".", "") || "";
					this.dots = 0;
				}
			}, 600);
		}
	}
	render() {
		return (
			<div id="loader">
				<img src={LoadingImg} alt="pizza-loader" width="192" />
				<h1>Les pizzas chauffent</h1>
			</div>
		);
	}
}
