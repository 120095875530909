import React, { Suspense, lazy } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

// ============ Import components ============
import Loading from "components/Loading/Loading";
// ============ Import components ============

import "./assets/fonts/hotpizza.woff2";

const Home = lazy(() => import("./views/Home/Home"));
const Recevoir = lazy(() => import("./views/Dashboard/Recevoir/Recevoir"));
const Envoyer = lazy(() => import("./views/Dashboard/Envoyer/Envoyer"));
const Error = lazy(() => import("./views/Error/Error"));

function Main() {
	return (
		<Suspense fallback={<Loading />}>
			<Router>
				<Routes>
					<Route path="/envoyer" element={<Envoyer />} />
					<Route path="/recevoir" element={<Recevoir />} />
					<Route path="/" element={<Home />} />
					<Route path="*" element={<Error />} />
				</Routes>
			</Router>
		</Suspense>
	);
}

export default Main;
