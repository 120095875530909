import React from "react";
import ReactDOM from "react-dom/client";

// import reportWebVitals from "./reportWebVitals";

// ============ Import components ============
import Main from "./Main";
// ===========================================

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

// import "assets/fa/css/all.min.css";
import "assets/fonts/hotpizza.woff2";

import "./index.css";

//
// Redirect to https if not in dev mode
//
if (process.env.REACT_APP_ENV !== "dev" && window.location.protocol === "http:") {
	window.location.href = window.location.href.replace("http:", "https:");
}

const rootElement = document.getElementById("root");

if (rootElement == null) {
	throw new Error("Root element not found");
}

const root = ReactDOM.createRoot(rootElement);
root.render(
	<React.StrictMode>
		<Main />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
